
@media only screen and (max-width: 991px){
    .servicios{
        width: 100%;
        margin-top: 70px;
    }
    .servicios-header{
        width: 100%;
        height: 200px;
        overflow: hidden;
    }
        .servicios-header img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50%;
        }
    .servicios-body{
        width: 100%;
        margin: auto;
    }
    .servicios-texts{
        width:85%;
        margin: auto;
        padding: 50px 0 50px 0;
        color: var(--fondos);
    }
        .servicios-texts p:nth-child(1){
            font-family: 'Cedarville Cursive', cursive;;
        }
        .servicios-texts p:nth-child(2){
            font-family: 'Cedarville Cursive', cursive;;
            font-weight: 600;
            padding-top: 15px;
        }
    .servicios-piscina{
        width: 100%;
        padding: 45px 0 45px 0;
    }
        .servicios-piscina-img{
            overflow: hidden;
        }
            .servicios-piscina-img img{
                width: 100%;
                object-fit: cover;
                object-position: 50%;
            }
        .servicios-piscina-text{
            width: 80%;
            margin: auto;
        }
            .servicios-piscina-text h3{
                font-family: 'Cedarville Cursive', cursive;
                color: var(--fondos);
                font-weight: 600;
                padding-top: 20px;
            }
            .servicios-piscina-text p{
                color: var(--fondos);
                padding-top: 15px;
                text-align: justify;
                font-size: .9em;
            }
    .servicios-quincho{
        width: 100%;;
        padding: 45px 0 45px 0;
    }
        .quincho-desktop{
            display: none;
        }
        .quincho-cell{
            width: 100%;
            height: 250px;
            display: flex;
        }
            .quincho-cell div:nth-child(1){
                width: 50%;
                overflow: hidden;
            }
            .quincho-cell div:nth-child(2){
                width: 50%;
                overflow: hidden;
            }
                .quincho-cell div img{
                    width: 100%;
                    object-fit: cover;
                    object-position: 50%;
                }
        .servicios-quincho-text{
            width:80%;
            margin: auto;
        }
            .servicios-quincho-text h3{
                font-family: 'Cedarville Cursive', cursive;
                color: var(--fondos);
                font-weight: 600;
                padding: 20px 0 0 0;
            }
            .servicios-quincho-text p{
                color: var(--fondos);
                padding-top: 15px;
                text-align: justify;
                font-size: .9em;
            }
            .servicios-quincho-text button{
                background-color: var(--fondos);
                color: var(--logo);
                border: none;
                padding: 4px 10px;
                border-radius: 5px;
                font-size: .8em;
                margin: 30px 0;
            }
            .servicios-quincho-text button:hover{
                opacity: .5;
                transition: 1s;
            }
    .servicios-parador{
        width: 100%;
        padding: 45px 0 45px 0;
    }
        .servicios-parador-img-cell{
            width: 100%;
            overflow: hidden;
        }       
        .servicios-parador-img-cell img{
            width: 100%;
            overflow: hidden;
        }        
        .servicios-parador-img{
            display: none;
            overflow: hidden;
        }
            .servicios-parador-img img{
                width: 100%;
                object-fit: cover;
            }
        .servicios-parador-text{
            width: 80%;
            margin: auto;
        }
            .servicios-parador-text h3{
                font-family: 'Cedarville Cursive', cursive;
                color: var(--fondos);
                font-weight: 600;
                padding-top: 20px;
            }
            .servicios-parador-text p{
                color: var(--fondos);
                padding-top: 15px;
                text-align: justify;
                font-size: .9em;
            }
    .servicios-atencion{
        width: 100%;
        display: flex;
        padding: 45px 0 45px 0;
    }
        .atencion-desktop{
            display: none;
        }
        .servicios-atencion-cell-img{
            width: 100%;
            height: 250px;
            overflow: hidden;
        }
            .servicios-atencion-cell-img img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 0px -100px;
            }
        .servicios-atencion-cell-text{
            width: 80%;
            margin: auto;
        }
            .servicios-atencion-cell h3{
                font-family: 'Cedarville Cursive', cursive;
                color: var(--fondos);
                font-weight: 600;
                padding-top: 0px;
            }
            .servicios-atencion-cell p{
                color: var(--fondos);
                padding-top: 15px;
                text-align: justify;
                font-size: .8em;
            }
            .servicios-atencion-cell button{
                background-color: var(--fondos);
                color: var(--logo);
                border: none;
                padding: 4px 10px;
                border-radius: 5px;
                font-size: .8em;
                margin: 30px 0;
            }
            .servicios-atencion-cell button:hover{
                opacity: .5;
                transition: 1s;
            }
    .servicios-hectareas{
        padding: 45px 0 100px 0;
    }
        .servicios-hectareas-desktop{
            display: none;
        }
        .servicios-hectareas-cell{
            width: 100%;
        }
            .servicios-hectareas-cell-img{
                width: 100%;
                overflow: hidden;
            }
                .servicios-hectareas-cell-img img{
                    width: 100%;
                    object-fit: cover;
                }
        .servicios-hectareas-cell-text{
            width: 80%;
            margin: auto;
        }
            .servicios-hectareas-cell-text h3{
                font-family: 'Cedarville Cursive', cursive;
                color: var(--fondos);
                font-weight: 600;
                padding-top: 20px;
                text-align: center;
            }
            .servicios-hectareas-cell-text p{
                color: var(--fondos);
                padding-top: 20px;
                text-align: justify;
                font-size: .8em;
            }
}



@media only screen and (min-width: 992px){
    .servicios{
        width: 100%;
        margin-top: 70px;
    }
    .servicios-header{
        width: 100%;
        height: 400px;
        overflow: hidden;
    }
        .servicios-header img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50%;
        }
    .servicios-body{
        width: 85%;
        margin: auto;
    }
    .servicios-texts{
        width:60%;
        margin: auto;
        padding: 50px 0 50px 0;
        color: var(--fondos);
    }
        .servicios-texts p:nth-child(1){
            font-family: 'Cedarville Cursive', cursive;;
        }
        .servicios-texts p:nth-child(2){
            font-family: 'Cedarville Cursive', cursive;;
            font-weight: 600;
            padding-top: 15px;
        }
    .servicios-piscina{
        width: 100%;
        display: flex;
        padding: 45px 0 45px 0;
    }
        .servicios-piscina-img{
            width: 50%;
            overflow: hidden;
        }
            .servicios-piscina-img img{
                width: 100%;
                object-fit: cover;
                object-position: 50%;
            }
        .servicios-piscina-text{
            width: 50%;
            object-fit: cover;
            object-position: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 0 30px 0 30px;
        }
            .servicios-piscina-text h3{
                font-family: 'Cedarville Cursive', cursive;
                color: var(--fondos);
                font-weight: 600;
                padding-top: 15px;
            }
            .servicios-piscina-text p{
                color: var(--fondos);
                padding-top: 15px;
                text-align: justify;
                font-size: .9em;
            }
    .servicios-quincho{
        width: 100%;;
        display: flex;
        padding: 45px 0 45px 0;
    }
        .quincho-cell{
            display: none;
        }
        .servicios-quincho-img1{
            width:35%;
            overflow: hidden;
            display: flex;
            align-items: flex-end;
            justify-content: center ;
        }
            .servicios-quincho-img1 img{
                width:100%;
            }
        .servicios-quincho-text{
            width:40%;
            padding: 0 30px 0 30px;
        }
            .servicios-quincho-text h3{
                font-family: 'Cedarville Cursive', cursive;
                color: var(--fondos);
                font-weight: 600;
                padding: 15px 25px;
                text-align: end;
            }
            .servicios-quincho-text p{
                color: var(--fondos);
                padding-top: 15px;
                text-align: justify;
                font-size: .9em;
            }
            .servicios-quincho-text button{
                background-color: var(--fondos);
                color: var(--logo);
                border: none;
                padding: 4px 10px;
                border-radius: 5px;
                font-size: .8em;
                margin: 30px 0;
            }
            .servicios-quincho-text button:hover{
                opacity: .5;
                transition: 1s;
            }
        .servicios-quincho-img2{
            width:25%;
            overflow: hidden;
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }
            .servicios-quincho-img2 img{
                width:105%;
            }
    .servicios-parador{
        width: 100%;
        max-height: 300px;
        display: flex;
        padding: 45px 0 45px 0;
    }
        .servicios-parador-img-cell{
            display: none;
        }       
        .servicios-parador-img-cell img{
            display: none;
        }      
        .servicios-parador-img{
            width: 65%;
            overflow: hidden;
        }
            .servicios-parador-img img{
                width: 100%;
                object-fit: cover;
                object-position: 50% -100px;
            }
        .servicios-parador-text{
            width: 35%;
            object-fit: cover;
            object-position: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 0 30px 0 30px;
        }
            .servicios-parador-text h3{
                font-family: 'Cedarville Cursive', cursive;
                color: var(--fondos);
                font-weight: 600;
                padding-top: 15px;
            }
            .servicios-parador-text p{
                color: var(--fondos);
                padding-top: 15px;
                text-align: justify;
                font-size: .9em;
            }
    .servicios-atencion{
        width: 100%;
        display: flex;
        padding: 45px 0 45px 0;
    }
        .servicios-atencion-cell{
            display: none;
        }
        .servicios-atencion-left{
            width: 45%;
            overflow: hidden;
            padding: 0 30px;
        }
            .servicios-atencion-left img{
                width: 100%;
                object-fit: cover;
            }
            .servicios-atencion-left h3{
                font-family: 'Cedarville Cursive', cursive;
                color: var(--fondos);
                font-weight: 600;
                padding-top: 15px;
                text-align: left;
            }
            .servicios-atencion-left p{
                color: var(--fondos);
                padding-top: 15px;
                text-align: justify;
                font-size: .8em;
            }
            .servicios-atencion-right button{
                background-color: var(--fondos);
                color: var(--logo);
                border: none;
                padding: 4px 10px;
                border-radius: 5px;
                font-size: .8em;
                margin: 30px 0;
            }
            .servicios-atencion-right button:hover{
                opacity: .5;
                transition: 1s;
            }
        .servicios-atencion-right{
            width: 55%;
            height: 100%;
            overflow: hidden;
            padding: 0 50px;
        }
            .servicios-atencion-right img{
                width: 100%;
                object-fit: cover;
            }
    .servicios-hectareas{
        padding: 45px 0 150px 0;
    }
        .servicios-hectareas-cell{
            display: none;
        }
        .servicios-hectareas-desktop{
            width: 100%;
            display: flex;
        }
        .servicios-hectareas-desktop-left{
            width: 40%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
        }
            .servicios-hectareas-desktop-left img{
                width: 75%;
                object-fit: cover;
            }
        .servicios-hectareas-desktop-right{
            width: 60%;
            overflow: hidden;
            padding: 0 40px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;  
        }
            .servicios-hectareas-desktop-right h3{
                font-family: 'Cedarville Cursive', cursive;
                color: var(--fondos);
                font-weight: 600;
                padding-top: 15px;
                text-align: center;
            }
            .servicios-hectareas-desktop-right p{
                color: var(--fondos);
                padding-top: 20px;
                text-align: justify;
                font-size: .8em;
            }
            .servicios-hectareas-desktop-right img{
                margin-top: 30px;
                width: 100%;
                object-fit: cover;
            }
}

