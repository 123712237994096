@media only screen and (max-width: 991px){
    .historia{
        width: 100%;
        min-height: 600px;
    }
    .under-contruction-ico{
        color: var(--fondos);
        margin-top: 150px;
    }
    .under-contruction-p{
        color: var(--fondos);
    }
}

@media only screen and (min-width: 992px){
    .historia{
        width: 100%;
        height: 100%;
    }
    .under-contruction-ico{
        color: var(--fondos);
        margin-top: 150px;
    }
    .under-contruction-p{
        color: var(--fondos);
    }
}

