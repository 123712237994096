.not-found{
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.not-found h2{
    color: var(--fondos);
    font-family: 'Cedarville Cursive', cursive;
    margin-top: 100px;
}