
@media only screen and (max-width: 991px){
    .barrio{
        width: 100%;
        margin: 70px 0 0 0;
    }
    .barrio-carrousel{
        width: 100%;
        height: 300px;
        overflow: hidden;
    }
    .barrio-texts{
        width:80%;
        margin: auto;
    }
    .barrio-texts h2{
        font-family: 'Cedarville Cursive', cursive;
        color: var(--titulos);
        margin: 50px 0 20px 0;
        font-size: 1.3em;
    }
    .barrio-texts p{
        font-size: .9em;
        color: var(--textos);
    }
    .barrio-contacto{
        width: 100%;
        padding: 50px 0 150px 0;
    }
    .barrio-contacto a{
        text-decoration: none;
        font-size: 1em;
        font-weight: 100;
        background-color: var(--titulos);
        color: var(--logo);
        padding: 8px 13px;
        border-radius: 5px 5PX;
    }
}



@media only screen and (min-width: 992px){
    .barrio{
        width: 100%;
        margin: 70px 0 0 0;
    }
    .barrio-carrousel{
        width: 100%;
        height: 400px;
        overflow: hidden;
    }
    .barrio-texts{
        width:100%;
    }
    .barrio-texts h2{
        font-family: 'Cedarville Cursive', cursive;
        color: var(--titulos);
        margin: 50px 0 20px 0;
    }
    .barrio-texts p{
        font-size: 1em;
        color: var(--textos);
    }
    .barrio-contacto{
        width: 100%;
        padding: 50px 0 150px 0;
    }
    .barrio-contacto a{
        text-decoration: none;
        font-size: 1em;
        font-weight: 100;
        background-color: var(--titulos);
        color: var(--logo);
        padding: 8px 13px;
        border-radius: 5px 5PX;
    }
}

