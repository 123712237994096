
@media only screen and (max-width: 991px){
    .alquiler{
        width: 100%;
        margin: 70px 0 0 0;
    }
    .alquiler-header{
        width: 100%;
        height: 200px;
        overflow: hidden;
    }
        .alquiler-header img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    .alquiler-texts{
        width:100%;
        margin: 40px 0 70px 0;
        padding: 0 40px 0 40px;
    }
        .alquiler-texts-izq h2{
            font-family: 'Cedarville Cursive', cursive;
            color: var(--titulos);
            font-size: 1.3em;
            margin-bottom: 30px;
        }
        .alquiler-texts-izq p{
            font-size: 1em;
            color: var(--fondos);
            text-align: justify;
            margin-bottom: 20px;
        }
        .alquiler-texts-izq-link{
            margin-top: 40px;
        }
        .alquiler-texts-izq a{
            text-decoration: none;
            font-size: 1em;
            color: var(--logo);
            border: none;
            background-color: var(--titulos);
            padding: 7px 15px;
            border-radius: 15px;
        }
        .alquiler-text-der-box{
            background-color: var(--fondosclaros);
            margin: 50px 0 50px 0;
            padding: 30px 25px;
        }
            .alquiler-text-der-box h3{
                color: var(--textos);
                font-size: .8em;
                margin-bottom: 30px;
            }
            .alquiler-text-der-box li{
                font-size: .8em;
                color: var(--texts);
                text-align: justify;
                margin: 10px 15px;
            }
    /* CAB GRAL STYLE */
    .cab-text-desktop{
        display:none;
    }
    .cab{
        width: 100%;
        height: fit-content;
        overflow: hidden;
    }
    .cab-carrousel{
        width: 100%;
    }
        .cab-carrousel img{
            width: 100%;
            height: 100%;
        }
    .cab-text-cell{
        background-image: url('../../assets/pagina_de_inicio/fondo1.png');
        width: 80%;
        margin: auto;
        padding: 30px 0 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .cab h3{
            margin: 0 0 15px 0;
            color: var(--titulos);
            font-family: 'Cedarville Cursive', cursive;
            font-weight: 600;
            font-size: 1.3em;
            text-align: left;
        }
        .cab p{
            color: var(--textos);
            font-size: .9em;
            font-weight: 100;
            text-align: center;
        }
        .cab button{
            border: none;
            background-color: var(--fondos);
            color: var(--logo);
            padding: 3px 10px;
            border-radius: 5px;
            margin: 30px;
        }
    .cab:hover{
        box-shadow: 0 4px 8px 0 var(--titulos), 0 6px 20px 0 var(--textos);
        opacity:0.8;
        transition:1s;
    }
    .nuestros-servicios-link-bottom{
        padding: 0 0 70px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .nuestros-servicios-link-bottom img{
        width: 100%;
        margin: 0 0 0 0;
    }
    .nuestros-servicios-link-bottom a{
        text-decoration: none;
        font-size: .9em;
        color: var(--logo);
        border: none;
        background-color: var(--titulos);
        padding: 7px 15px;
        border-radius: 5px;
    }
}



@media only screen and (min-width: 992px){
    .alquiler{
        width: 100%;
        margin-top: 70px;
    }
    .alquiler-header{
        width: 100%;
        height: 400px;
        overflow: hidden;
    }
        .alquiler-header img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50%;
        }
    .alquiler-body{
        width: 85%;
        margin: auto;
    }
        .alquiler-texts{
            width:100%;
            margin: 50px 0 50px 0;
            display: flex;
        }
            .alquiler-texts-izq{
                width: 50%;
                padding: 0 40px 0 40px;
            }
                .alquiler-texts-izq h2{
                    font-family: 'Cedarville Cursive', cursive;
                    color: var(--titulos);
                    text-align: left;
                    margin-bottom: 20px;
                }
                .alquiler-texts-izq p{
                    font-family: 'Cedarville Cursive', cursive;
                    font-size: 1em;
                    color: var(--titulos);
                    margin-bottom: 20px;
                    text-align: justify;
                }
                .alquiler-texts-izq-link{
                    margin-top: 40px;
                    text-align: right;
                }
                .alquiler-texts-izq a{
                    text-decoration: none;
                    color: var(--logo);
                    background-color: var(--titulos);
                    font-size: 1em;
                    border: none;
                    padding: 7px 15px;
                    border-radius: 5px;
                }
            .alquiler-texts-der{
                width: 50%;
                padding: 0 40px 0 40px;
            }
                .alquiler-text-der-box{
                    background-color: var(--fondosclaros);
                    padding: 30px 50px;
                }
                .alquiler-texts-der h3{
                    font-size: .9em;
                    color: var(--textos);
                    text-align: left;
                    margin-bottom: 20px;
                }
                .alquiler-texts-der li{
                    text-align: justify;
                    font-size: .9em;
                    color: var(--textos);
                    margin-bottom: 5px;
                }
    /* GENERAL STYLE CABS */
    .cab-text-cell{
        display:none;
    }
    .cab{
        display: flex;
        width: 100%;
        height: 400px;
        overflow: hidden;
        cursor: pointer;
    }
        .cab-carrousel{
            width: 57%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
            .cab-carrousel img{
                width: 90%;
                height: 90%;
                object-fit: contain;
                object-position: 50% 50%;
            }
        .cab-text-desktop{
            background-image: url('../../assets/pagina_de_inicio/fondo2.png');
            width: 48%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 70px 0 50px;
        }
            .cab-text-desktop h3{
                margin-bottom: 15px;
                color: var(--titulos);
                font-family: 'Cedarville Cursive', cursive;
                font-weight: 600;
                font-size: 1.3em;
            }
            .cab-text-desktop p{
                color: var(--textos);
                font-size: .9rem;
                font-weight: 100;
                direction: ltr;
                margin: 5px 0 5px 0;
            }
            .cab-button{
                border: none;
                margin: 0;
                padding: 0;
                color: none;
                background-color: none;
                z-index: -1;
            }
            .cab button{
                border: none;
                width: fit-content;
                background-color: var(--fondos);
                color: var(--logo);
                padding: 3px 10px;
                border-radius: 5px;
                margin: 30px 0;
            }
    .cab:hover{
        box-shadow: 0 4px 8px 0 var(--titulos), 0 6px 20px 0 var(--textos);
        opacity:0.8;
        transition:1s;
    }
    .nuestros-servicios-link-bottom{
        padding: 20px 0 100px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .nuestros-servicios-link-bottom img{
        width: 70%;
        margin: 0 0 0 0;
    }
    .nuestros-servicios-link-bottom a{
        text-decoration: none;
        font-size: 1em;
        color: var(--logo);
        border: none;
        background-color: var(--titulos);
        padding: 7px 15px;
        border-radius: 5px;
    }
}

