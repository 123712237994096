.seccion{
    width: 100%;
}

@media only screen and (max-width: 991px){

    .sec3-text-desktop,
    .sec4-text-desktop,
    .sec5-text-desktop{
        display:none;
    }

    .sec1{
        display: flex;
        width: 100%;
        height: 400px;
    }
    .sec1 video{
        width: 100%;
        object-fit: cover;
    }



    .sec2{
        background-color: var(--fondosclaros);
        padding: 50px 30px;
    }
    .sec2-box{
        background-color: var(--fondos);
        padding: 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .sec2 h2{
        margin-bottom: 20px;
        color: var(--logo);
        font-family: 'Cedarville Cursive', cursive;
        font-weight: 100;
        font-size: 1.3rem;
    }
    .sec2 p{
        width: 80%;
        color: var(--blanco);
        font-size: .9rem;
        font-weight: 100;
        text-align: justify;
    }



    .sec3{
        width: 100%;
        overflow: hidden;
    }
    .sec3-carrousel img{
        width: 100%;
        height: 100%;
    }
    .sec3-text-cell{
        background-image: url('../../assets/pagina_de_inicio/fondo1.png');
        width: 80%;
        margin: auto;
        padding: 30px 0 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sec3 h3{
        margin-bottom: 15px;
        color: var(--textos);
        font-weight: 600;
        font-size: 1.2em;
        text-align: left;
    }
    .sec3 p{
        margin-bottom: 30px;
        color: var(--textos);
        font-size: .9rem;
        font-weight: 100;
        text-align: center;
    }
    .sec3 button{
        border: none;
        background-color: var(--fondos);
        color: var(--blanco);
        font-size: .9em;
        width: fit-content;
        padding: 7px 17px;
        border-radius: 10px 10px;
    }



    .sec4{
        width: 100%;
        overflow: hidden;
    }
    .sec4-carrousel img{
        width: 100%;
        height: 100%;
    }
    .sec4-text-cell{
        background-image: url('../../assets/pagina_de_inicio/fondo1.png');
        width: 80%;
        margin: auto;
        padding: 30px 0 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sec4 h3{
        margin-bottom: 15px;
        color: var(--textos);
        font-weight: 600;
        font-size: 1.2em;
        text-align: left;
    }
    .sec4 p{
        margin-bottom: 30px;
        color: var(--textos);
        font-size: .9rem;
        font-weight: 100;
        text-align: center;
    }
    .sec4 button{
        border: none;
        background-color: var(--fondos);
        color: var(--blanco);
        font-size: .9em;
        width: fit-content;
        padding: 7px 17px;
        border-radius: 10px 10px;
    }


    .sec5{
        width: 100%;
        overflow: hidden;
    }
    .sec5-carrousel img{
        width: 100%;
        height: 100%;
    }
    .sec5-text-cell{
        background-image: url('../../assets/pagina_de_inicio/fondo1.png');
        width: 80%;
        margin: auto;
        padding: 30px 0 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sec5 h3{
        margin-bottom: 15px;
        color: var(--textos);
        font-weight: 600;
        font-size: 1.2em;
        text-align: left;
    }
    .sec5 p{
        margin-bottom: 30px;
        color: var(--textos);
        font-size: .9rem;
        font-weight: 100;
        text-align: center;
    }
    .sec5 button{
        border: none;
        background-color: var(--fondos);
        color: var(--blanco);
        font-size: .9em;
        width: fit-content;
        padding: 7px 17px;
        border-radius: 10px 10px;
    }



    .sec6{
        background-color: var(--fondosclaros);
        padding: 50px 30px 100px 30px;
    }
    .sec6-text-desktop{
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .sec6 h3{
        color: var(--titulos);
        font-size: 1.8em;
        font-weight: 600;
        margin-bottom: 30px;
    }
    .sec6 p{
        color: var(--textos);
        font-family: 'Cedarville Cursive', cursive;
        font-size: 1em;
        font-weight: 100;
        text-align: justify;
    }
    .sec6 button{
        border: none;
        background-color: var(--fondos);
        color: var(--blanco);
        font-size: 1rem;
        width: fit-content;
        padding: 7px 17px;
        border-radius: 10px 10px;
        margin-top: 50px;
        
    }
    .sec6-carrousel{
        display: none;
    }
    
    
    
    .inicio-links{
        text-decoration: none;
    }
    .sec3:hover,
    .sec4:hover,
    .sec5:hover,
    .sec6:hover{
        box-shadow: 0 4px 8px 0 var(--titulos), 0 6px 20px 0 var(--textos);
        opacity:0.8;
        transition:1s;
    }

}






@media only screen and (min-width: 992px){

    .sec3-text-cell,
    .sec4-text-cell,
    .sec5-text-cell,
    .sec6-text-cell{
        display:none;
    }

    .sec1{
        display: flex;
        width: 100%;
        height: 570px;
    }
    .sec1 video{
        width: 100%;
        object-fit: cover;
    }



    .sec2{
        background-color: var(--fondosclaros);
        padding: 60px 0 60px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .sec2-box{
        background-color: var(--fondos);
        width: 65%;
        text-align: center;
        padding: 40px 50px;
    }
    .sec2 h2{
        color: var(--logo);
        font-family: 'Cedarville Cursive', cursive;
        font-weight: 100;
        margin: 0 0 30px 0;
    }
    .sec2 p{
        color: var(--blanco);
        margin: auto;
        font-size: .9rem;
        font-weight: 100;
        text-align: justify;
    }



    .sec3{
        display: flex;
        width: 100%;
        height: 400px;
        overflow: hidden;
    }
    .sec3-carrousel{
        width: 55%;
        height: 100%;
    }
    .sec3-carrousel img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
    .sec3-text-desktop{
        background-image: url('../../assets/pagina_de_inicio/fondo1.png');
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0 0 50px;
    }
    .sec3 h3{
        color: var(--textos);
        font-weight: 600;
        font-size: 1.3em;
        text-align: left;
    }
    .sec3 p{
        color: var(--textos);
        font-size: 1.05rem;
        font-weight: 100;
        text-align: justify;
        direction: ltr;
        margin: 20px 0 20px 0;
    }
    .sec3 button{
        border: none;
        background-color: var(--fondos);
        color: var(--blanco);
        font-size: 1rem;
        width: fit-content;
        padding: 7px 17px;
        border-radius: 10px 10px;
    }



    .sec4{
        display: flex;
        width: 100%;
        height: 400px;
        overflow: hidden;
    }
    .sec4-carrousel{
        width: 55%;
        height: 100%;
    }
    .sec4-carrousel img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
    .sec4-text-desktop{
        background-image: url('../../assets/pagina_de_inicio/fondo1.png');
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        padding: 0 50px 0 0;
    }
    .sec4 h3{
        color: var(--textos);
        font-weight: 600;
        font-size: 1.3em;
        text-align: left;
    }
    .sec4 p{
        color: var(--textos);
        font-size: 1.05rem;
        font-weight: 100;
        text-align: justify;
        direction: ltr;
        margin: 20px 0 20px 0;
    }
    .sec4 button{
        border: none;
        background-color: var(--fondos);
        color: var(--blanco);
        font-size: 1rem;
        width: fit-content;
        padding: 7px 17px;
        border-radius: 10px 10px;
    }



    .sec5{
        display: flex;
        width: 100%;
        height: 400px;
        overflow: hidden;
    }
    .sec5-carrousel{
        width: 55%;
        height: 100%;
    }
    .sec5-carrousel img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
    .sec5-text-desktop{
        background-image: url('../../assets/pagina_de_inicio/fondo1.png');
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0 0 50px;
    }
    .sec5 h3{
        color: var(--textos);
        font-weight: 600;
        font-size: 1.3em;
        text-align: left;
    }
    .sec5 p{
        color: var(--textos);
        font-size: 1.05rem;
        font-weight: 100;
        text-align: justify;
        direction: ltr;
        margin: 20px 0 20px 0;
    }
    .sec5 button{
        border: none;
        background-color: var(--fondos);
        color: var(--blanco);
        font-size: 1rem;
        width: fit-content;
        padding: 7px 17px;
        border-radius: 10px 10px;
    }



    .sec6{
        display: flex;
        width: 100%;
        overflow: hidden;
        background-color: var(--fondosclaros);
        padding: 50px 30px 100px 30px;
    }
    .sec6-carrousel{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sec6-carrousel img{
        width: 80%;
    }
    .sec6-text-desktop{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 50px 0 50px;
    }
    .sec6 h3{
        color: var(--titulos);
        font-size: 1.8em;
        font-weight: 600;
        margin: 0 0 50px 0;
    }
    .sec6 p{
        color: var(--textos);
        font-family: 'Cedarville Cursive', cursive;
        font-size: 1.05rem;
        font-weight: 100;
        text-align: justify;
    }
    .sec6 button{
        border: none;
        background-color: var(--fondos);
        color: var(--blanco);
        font-size: 1rem;
        width: fit-content;
        padding: 7px 17px;
        border-radius: 10px 10px;
        margin-top: 50px;
        
    }



    .inicio-links{
        text-decoration: none;
    }
    .sec3:hover,
    .sec4:hover,
    .sec5:hover{
        box-shadow: 0 4px 8px 0 var(--titulos), 0 6px 20px 0 var(--textos);
        opacity:0.8;
        transition:1s;
    }
}