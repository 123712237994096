
@media only screen and (max-width: 991px){
    .experiencia{
        width: 100%;
        margin: 70px 0 0 0;
    }
    .experiencia-carrousel{
        width: 100%;
        height: 300px;
        overflow: hidden;
    }
    .experiencia-texts{
        width:100%;
        padding: 50px 40px 150px 40px;
    }
    .experiencia-texts h2{
        font-family: 'Cedarville Cursive', cursive;
        color: var(--titulos);
        margin: 0 0 20px 0;
    }
    .experiencia-texts p{
        font-size: 1em;
        color: var(--textos);
        margin: 0 0 50px 0;
    }
    .experiencia-texts h3{
        font-size: 1em;
        font-weight: 100;
        color: var(--titulos);
        margin: 0 0 20px 0;
    }
    .experiencia-texts h4{
        font-family: 'Cedarville Cursive', cursive;
        color: var(--titulos);
        font-size: 1.5em;
        font-weight: 600;
        
    }
}



@media only screen and (min-width: 992px){
    .experiencia{
        width: 100%;
        margin: 70px 0 0 0;
    }
    .experiencia-carrousel{
        width: 100%;
        height: 400px;
        overflow: hidden;
    }
    .experiencia-texts{
        width:100%;
        padding: 50px 0 150px 0;
    }
    .experiencia-texts h2{
        font-family: 'Cedarville Cursive', cursive;
        color: var(--titulos);
        margin: 0 0 15px 0;
    }
    .experiencia-texts p{
        font-size: 1em;
        color: var(--textos);
        margin: 0 0 50px 0;
    }
    .experiencia-texts h3{
        font-size: 1em;
        font-weight: 100;
        color: var(--titulos);
        margin: 0 0 15px 0;
    }
    .experiencia-texts h4{
        font-family: 'Cedarville Cursive', cursive;
        color: var(--titulos);
        font-size: 1.5em;
        font-weight: 600;
        
    }
}

