@import url('https://fonts.googleapis.com/css2?family=Belleza&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');

:root{
    --blanco: #ffff;
    --fondogeneral: #FAF9F8;
    --fondos: #7d8d6c;
    --fondosclaros: #BCD1A9;
    --titulos: #7d8d6c;
    --logo: #dae7cf;
    --textos: #434343;
}
*{
    margin: 0;
    padding: 0;
    font-family: 'Belleza', sans-serif;
    box-sizing: border-box;
    line-height: 1.5;
}
.home{
    width: 100%;
}


@media only screen and (max-width: 991px){
    .header{
        background-color: var(--fondos);
        width: 100%;
        min-height: 70px;
        position: fixed;
        top: 0;
        z-index: 1000;
    }
        .header-izquierda{
            width: 100%;
            height: 70px;
            display: flex;
            align-items: center;
        }
            .header-hamburga{
                width: 15%;
                text-align: center;
            }
            .header-hamburga button{
                border: none;
                background-color: inherit;
                color: var(--logo);
            }
            .header-logo{
                width: 70%;
                text-align: center;
            }
            .header-logo a{
                color: var(--logo);
                text-decoration: none;
                color: var(--blanco);
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
            }
                .header-logo h1{
                    font-family: 'Cedarville Cursive', cursive;
                    font-size: 1.5em;
                    font-weight: 500;
                    margin-top: 10px;
                }
                .header-logo img{
                    margin: 0 0 0 10px;
                    width: 30px;
                    height: 30px;
                }
            .header-compensa{
                width: 15%;
            }
        .header-menu-desktop{
            display: none;
        }
        .header-menu{
            width: 90%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            padding: 0 0 20px 0;
        }
        .header-menu a{
            color: var(--blanco);
            text-decoration: none;
            font-size: .9em;
            margin: 10px 0 10px 0;
            padding: 0 0 5px 0;
            border-bottom: 1px solid var(--fondosclaros);
        }
    .body{
        width: 100%;
        min-height:400px;
        text-align: center;
        margin: 70px 0 0 0 ;
        background-color: var(--fondogeneral);
    }
    .footer{
        background-color: var(--fondos);
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 50px 0 50px 0;
    }
        .footer-ubicacion{
            width:50%;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-decoration: none;
            color: var(--logo);
            text-align: center;
        }
            .footer-ubicacion h3{
                margin: 0 0 15px 0;
                font-weight: 100;
            }
            .footer-ubicacion p{
                font-size: .9em;
            }
        .footer-contacto{
            width:50%;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-decoration: none;
            color: var(--logo);
            text-align: center;
        }
            .footer-contacto h3{
                margin: 0 0 15px 0;
                font-weight: 100;
            }
            .footer-contacto p{
                font-size: .9em;
            }
        .footer-redes{
            padding:50px 0 0 0;
            width:100%;
            display: flex;
            justify-content: center;
        }
            .footer-redes a{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 70px;
                color: var(--logo);
            }
            .footer-redes img{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 70px;
                color: var(--logo);
            }
        .footer-creditos{
            width:100%;
            display: flex;
            justify-content: center;
            padding: 50px 20px 0 20px;
            color: var(--logo);
            text-align: center;
            font-size: .9em;
        }
    /* MODAL */
    .modal-fotos{
        width: 95%;
        height: 35%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: #fff;
        z-index: 1;
        box-shadow: 1px 1px 5px var(--textos);
        overflow: hidden;
    }
        .close-modal-fotos{
            position:absolute;
            top: 10px;
            right:10px;
            z-index: 1000;
        }
        .close-modal-fotos button{
            border: none;
            background-color: transparent;
            color: var(--logo);
        }
}









@media only screen and (min-width: 992px){
    .header{
        background-color: var(--fondos);
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 1000;
    }
        .header-izquierda{
            width: 30%;
            margin: 0 0 0 50px;
        }
        .header-hamburga{
            display:none;
        }
        .header-logo a{
            color: var(--blanco);
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
            .header-logo h1{
                font-family: 'Cedarville Cursive', cursive;
                font-size: 1.5em;
                font-weight: 500;
                margin-top: 10px;
            }
            .header-logo img{
                margin: 0 0 0 10px;
                width: 30px;
                height: 30px;
            }
        .header-menu-desktop{
            width: 70%;
            display: flex;
            justify-content: flex-end;
            padding: 0 30px 0 0;
        }
        .header-menu-desktop a{
            color: var(--blanco);
            text-decoration: none;
            font-size: .8em;
            margin: 0 10px 0 10px;
        }
    .body{
        width: 100%;
        min-height:450px;
        text-align: center;
        margin: 70px 0 0 0 ;
        background-color: var(--fondogeneral);
    }
    .footer{
        background-color: var(--fondos);
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0 20px 0;
        z-index: 1000;
    }
        .footer-ubicacion{
            width:15%;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-decoration: none;
            color: var(--logo);
        }
            .footer-ubicacion h3{
                margin: 0 0 10px 0;
                font-weight: 100;
                font-size: .9em;
            }
            .footer-ubicacion p{
                margin: 0 0 2px 0;
                font-size: .7em;
                font-weight: 100;
            }
        .footer-contacto{
            width:15%;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-decoration: none;
            color: var(--logo);
        }
            .footer-contacto h3{
                margin: 0 0 10px 0;
                font-weight: 100;
                font-size: .9em;
            }
            .footer-contacto p{
                margin: 0 0 2px 0;
                font-size: .7em;
                font-weight: 100;
            }
        .footer-redes{
            width:70%;
            display: flex;
            justify-content: flex-end;
            padding: 0 50px 0 0;
        }
            .footer-redes a{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 70px;
                color: var(--logo);
            }
            .footer-redes img{
                width: 60px;
            }
        .footer-creditos{
            width:100%;
            display: flex;
            justify-content: center;
            padding: 10px 20px 0 20px;
            color: var(--logo);
            font-size: .9em;
        }

    /* MODAL */
    .modal-fotos{
        width: 70%;
        height: 80%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: #fff;
        z-index: 1;
        box-shadow: 1px 1px 5px var(--textos);
        overflow: hidden;
    }
        .close-modal-fotos{
            position:absolute;
            top: 10px;
            right:10px;
            z-index: 1000;
        }
        .close-modal-fotos button{
            border: none;
            background-color: transparent;
            color: var(--logo);
        }
}






/* --- H I D E --- */
.hide{
    display: none;
    transition: 1s;
}