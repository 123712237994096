@media only screen and (max-width: 991px){
    .contacto{
        width: 80%;
        margin: 0 auto;
        padding: 50px 0 100px 0;
    }
    .contacto-sec1 h2{
        font-family: 'Cedarville Cursive', cursive;
        color: var(--titulos);
        font-size: 1.5em;
        margin-bottom: 30px;
    }
    .contacto-sec1-box-map{
        height: 300px;
    }
        .google-maps{
            width: 100%;
            height: 100%;
            border: none;
        }
    .contacto-sec1-box-text{
        margin: 50px 0 50px 0;
    }
        .contacto-sec1-box-text p{
            text-align: justify;
            font-size: .9em;
        }
    .contacto-sec2 h2{
        font-family: 'Cedarville Cursive', cursive;
        color: var(--titulos);
        font-size: 1.5em;
    }
    .contacto-sec1-box-text p{
        text-align: justify;
        font-size: .9em;
    }
    .contacto-sec3-p{
        text-align: center;
        color: var(--titulos);
        font-size: 1em;
        font-weight: 00;
        margin: 50px 0 50px 0;
    }
    .form{
        width: 100%; 
        margin: auto;
        display: flex;            
        flex-direction: column;
        align-items: center;
    }
        .form-input{
            border: none;
            border-bottom: 1px solid var(--fondos);
            width: 100%;
            height: 25px;
            margin: 0 0 10px 0;
            background-color: inherit;
        }
        .text-area{
            min-height: 100px;
        }
        .form-input:focus{
            outline: none;
        }
        .form-input::placeholder{
            color: var(--fondos);
        }
        .form-enviar{
            margin: 20px 0 0 0;
            border: none;
            background-color:var(--fondos);
            color: var(--logo);
            padding: 7px 15px;
            border-radius: 15px 15px;
        }
    .modal-page{
        width: 90%;
        height: 250px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: #fff;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 1px 1px 5px var(--fondos);
    }
        .modal-page p {
            color: var(--fondos);
            text-align: center;
        }
        .modal-page button {
            background-color: var(--fondos);
            color: var(--logo);
            border: none;
            border-radius: 15px 15px;
            padding: 7px 15px;
            margin: 20px 0 0 0;
        }
}



@media only screen and (min-width: 992px){

    .contacto{
        padding-top: 70px;
        width: 80%;
        margin: auto;
    }
    .contacto-sec1{
        width: 100%;
        height: fit-content;
        margin: 0 0 50px 0;
    }
        .contacto-sec1 h2{
            font-family: 'Cedarville Cursive', cursive;
            color: var(--titulos);
            font-size: 1.5em;
        }
        .contacto-sec1-box{
            width: 100%;
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
            .contacto-sec1-box-map{
                width: 50%;
                height: 100%;
                padding: 20px;
            }
                .google-maps{
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            .contacto-sec1-box-text{
                width: 50%;
                padding: 0 40px;
            }
            .contacto-sec1-box-text p{
                text-align: justify;
                font-size: .9em;
            }


    .contacto-sec2{
        width: 100%;
        height: fit-content;
    }
        .contacto-sec2 h2{
            font-family: 'Cedarville Cursive', cursive;
            color: var(--titulos);
            font-size: 1.5em;
        }
        .contacto-sec2-box{
            width: 100%;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
            .contacto-sec2-box-text{
                width: 50%;
                padding: 0 40px;
            }
            .contacto-sec1-box-text p{
                text-align: justify;
                font-size: .9em;
            }
            .contacto-sec1-box-data{
                width: 50%;
                padding: 0 40px;
            }
            
            
    .contacto-sec3{
        width: 100%;
        height: fit-content;
        padding: 40px 0 100px 0;
    }
        .contacto-sec3-p{
            text-align: center;
            color: var(--titulos);
            font-size: .9em;
            font-weight: 600;
            margin: 0 0 50px 0;
        }
        .form{
            width: 60%;
            margin: auto;
            display: flex;            
            flex-direction: column;
            align-items: center;
        }
            .form-input{
                border: none;
                border-bottom: 1px solid var(--fondos);
                background-color: inherit;
                width: 70%;
                height: 25px;
                margin: 0 0 10px 0;
            }
            .text-area{
                min-height: 100px;
            }
            .form-input:focus{
                outline: none;
            }
            .form-input::placeholder{
                color: var(--fondos);
            }
            .form-enviar{
                margin: 20px 0 0 0;
                border: none;
                background-color:var(--fondos);
                color: var(--logo);
                padding: 7px 15px;
                border-radius: 15px 15px;
                cursor:pointer;
            }
    .modal-page{
        width: 500px;
        height: 150px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: #fff;
        background-image: url('../../assets/pagina_de_inicio/fondo1.png');
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 1px 1px 5px var(--fondos);
    }
        .modal-page p {
            color: var(--fondos);
        }
        .modal-page button {
            background-color: var(--fondos);
            color: var(--logo);
            border: none;
            border-radius: 15px 15px;
            padding: 7px 15px;
            margin: 20px 0 0 0;
            cursor:pointer;
        }
}