.whatshapp-floating-a{
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 100;
}
.whatshapp-floating-ico{
    width: 70px;
    height: 70px;
    color: var(--fondos);
    font-size: 50px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
}